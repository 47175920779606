import React, { useState, useCallback, useEffect } from "react";
import Modal from "react-modal";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { PacmanLoader } from "react-spinners";
import PoolForm from "./PoolForm";
import { savePoolAndInvite, updatePoolDetails, canEditRecord, validateFormData } from "./PoolModalHelper";
import PoolModalNavBar from "./PoolModalNavBar";
import ManagePoolEntries from "./ManagePoolEntries";
import InviteToPool from "./InviteToPool";
import './PoolDetailsModal.css';
import { logError } from "../Utilities/errorLogger";

Modal.setAppElement("#root");

const ModalContent = React.memo(function ModalContent({ 
  type, 
  handleSave, 
  handleClose, 
  poolData, 
  mode, 
  isPoolOwner,
  setFormData,
  currentTab,
  setCurrentTab,
  errors,
  setErrors,
  onTeamStatusChange,
  onAddEmail,
  onSendInvites,
  isSubmitting,
  setCanEdit
}) {
  const canEdit = canEditRecord(poolData);

  // Update parent's canEdit state when it changes
  React.useEffect(() => {
    setCanEdit(canEdit);
  }, [canEdit, setCanEdit]);

  const canInvitePlayers = poolData?.tournament_status === "Not Started" || 
    (poolData?.tournament_status !== "Not Started" && poolData?.can_invite_late_players);

  return (
    <div>
      <div className="pool-modal-tabs">
        <button 
          className={`pool-modal-tab ${currentTab === "edit" ? "active" : ""}`}
          onClick={() => setCurrentTab("edit")}
        >
          Edit Pool
        </button>
        <button 
          className={`pool-modal-tab ${currentTab === "manage" ? "active" : ""}`}
          onClick={() => setCurrentTab("manage")}
        >
          Manage Entries
        </button>
        <button 
          className={`pool-modal-tab ${currentTab === "invite" ? "active" : ""}`}
          onClick={() => setCurrentTab("invite")}
          disabled={!canInvitePlayers}
        >
          Invite Players
        </button>
      </div>

      {currentTab === "edit" && (
        <PoolForm 
          handleClose={handleClose} 
          poolData={poolData} 
          mode={mode} 
          isPoolOwner={isPoolOwner}
          setFormData={setFormData}
          canEdit={canEdit}
          setCanEdit={setCanEdit}
          activeTab={currentTab}
          errors={errors}
          setErrors={setErrors}
        />
      )}
      {currentTab === "manage" && (
        <ManagePoolEntries 
          poolId={poolData.id} 
          canEdit={canEdit}
          onTeamStatusChange={onTeamStatusChange}
        />
      )}
      {currentTab === "invite" && canInvitePlayers && (
        <div key="invite-tab">
          <InviteToPool 
            poolId={poolData.id}
            onAddEmail={onAddEmail}
            onSendInvites={onSendInvites}
            isSubmitting={isSubmitting}
            onClose={handleClose}
          />
        </div>
      )}
    </div>
  );
});

const ContentModal = ({ isOpen, onClose, poolData, mode, refreshData, loggedInUser, onTeamStatusChange }) => {
  const isPoolOwner = poolData && poolData.owner != null && poolData.owner === Number(loggedInUser);
  const [currentTab, setCurrentTab] = useState("edit");
  const [formData, setFormData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [addEmailCallback, setAddEmailCallback] = useState(null);
  const [sendInvitesCallback, setSendInvitesCallback] = useState(null);
  const [canEdit, setCanEdit] = useState(() => canEditRecord(poolData));

  // Update canEdit when poolData changes
  useEffect(() => {
    setCanEdit(canEditRecord(poolData));
  }, [poolData]);

  const handleClose = useCallback(() => {
    setCurrentTab("edit");
    setFormData(null);
    setErrors({});
    onClose();
  }, [onClose]);

  const handleTeamStatusChange = useCallback((teams) => {
    // Just pass through the teams update without any side effects
    if (onTeamStatusChange) {
      onTeamStatusChange(teams);
    }
  }, [onTeamStatusChange]);

  const handleSave = useCallback(async () => {
    if (!formData) {
      return;
    }
    
    const validationErrors = validateFormData(formData);
    
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    
    try {
      setIsSubmitting(true);
      
      if (mode === "insert") {
        await savePoolAndInvite(formData);
        handleClose();
      } else if (mode === "edit") {
        await updatePoolDetails(formData, poolData);
        if (refreshData) {
          await refreshData();
        }
        handleClose();
      }
    } catch (error) {
      await logError({
        filePath: 'Pool/PoolDetailsModal.js',
        functionName: 'handleSave',
        errorType: 'Save Data Error',
        errorMessage: error.message,
        stackTrace: error.stack,
      });
      console.error("Failed to save data:", error);
    } finally {
      setIsSubmitting(false);
    }
  }, [formData, mode, refreshData, handleClose, poolData]);

  const handleAddEmail = useCallback(() => {
    if (addEmailCallback) {
      addEmailCallback();
    }
  }, [addEmailCallback]);

  const handleSendInvites = useCallback(async () => {
    if (sendInvitesCallback) {
      setIsSubmitting(true);
      try {
        await sendInvitesCallback();
        handleClose();
      } finally {
        setIsSubmitting(false);
      }
    }
  }, [sendInvitesCallback, handleClose]);

  const setAddEmailCallbackMemoized = useCallback((fn) => {
    setAddEmailCallback(() => fn);
  }, []);

  const setSendInvitesCallbackMemoized = useCallback((fn) => {
    setSendInvitesCallback(() => fn);
  }, []);

  return (
    <Modal 
      isOpen={isOpen} 
      onRequestClose={handleClose} 
      contentLabel="Dynamic Content Modal" 
      className="pool-modal-content" 
      overlayClassName="pool-modal-overlay"
    >
      <div className="pool-modal-close-container">
        <button 
          type="button" 
          name="close" 
          className="pool-modal-close-button" 
          onClick={handleClose}
          disabled={isSubmitting}
        >
          <AiOutlineCloseCircle size="2.5em" />
        </button>
      </div>
      <div className="pool-modal-inner-container">
        {isSubmitting && currentTab !== "manage" && (
          <div className="pool-modal-spinner-overlay">
            <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
          </div>
        )}
        <div className={`pool-modal-body ${currentTab === "edit" ? "edit-mode" : ""}`}>
          {!isPoolOwner ? (
            <PoolForm 
              handleClose={handleClose} 
              poolData={poolData} 
              mode={mode} 
              isPoolOwner={isPoolOwner}
              setFormData={setFormData}
              canEdit={canEdit}
              setCanEdit={setCanEdit}
              activeTab={currentTab}
              errors={errors}
              setErrors={setErrors}
            />
          ) : (
            <ModalContent
              type={currentTab}
              handleSave={handleSave}
              handleClose={handleClose}
              poolData={poolData}
              mode={mode}
              isPoolOwner={isPoolOwner}
              setFormData={setFormData}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              errors={errors}
              setErrors={setErrors}
              onTeamStatusChange={handleTeamStatusChange}
              onAddEmail={setAddEmailCallbackMemoized}
              onSendInvites={setSendInvitesCallbackMemoized}
              isSubmitting={isSubmitting}
              setCanEdit={setCanEdit}
            />
          )}
        </div>
        {currentTab !== "manage" && (
          <PoolModalNavBar 
            activeSection={currentTab}
            isPoolOwner={isPoolOwner} 
            poolRecord={poolData}
            handleSave={handleSave}
            mode={mode}
            canEdit={canEdit}
            showSaveButton={currentTab === "edit"}
            showInviteButtons={currentTab === "invite"}
            onAddEmail={handleAddEmail}
            onSendInvites={handleSendInvites}
            isSubmitting={isSubmitting}
          />
        )}
      </div>
    </Modal>
  );
};

export default React.memo(ContentModal);
