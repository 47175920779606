import React, { useState, useEffect } from "react";
import TeamLineItemsTable from "./TeamLineItemsTable";
import { IoChevronBackCircle, IoChevronDownCircle, IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { TbGolfOff } from "react-icons/tb";
import { FaTrophy } from "react-icons/fa";

function TeamTable({ teams, teamLineItems, canViewTeam, isTournamentStarted }) {
  const [expandedRows, setExpandedRows] = useState({});
  const [previousRanks, setPreviousRanks] = useState({});

  // Sort teams by rank first, then by world ranking for ties
  const sortedTeams = React.useMemo(() => {
    const currentUserId = Number(localStorage.getItem("userId"));
    
    return [...teams].sort((a, b) => {
      // Parse ranks carefully
      const parseRank = (rank) => {
        if (!rank) return Infinity;
        const rankStr = rank.toString().toUpperCase();
        if (rankStr === 'MC') return Infinity;
        // Remove 'T' and convert to number, handle invalid cases
        const numericRank = parseInt(rankStr.replace('T', ''), 10);
        return isNaN(numericRank) ? Infinity : numericRank;
      };

      // Handle MC cases first
      const isMCA = a.rank?.toString().toUpperCase() === "MC";
      const isMCB = b.rank?.toString().toUpperCase() === "MC";

      if (isMCA && !isMCB) return 1;
      if (!isMCA && isMCB) return -1;

      // Get numeric ranks
      const rankA = parseRank(a.rank);
      const rankB = parseRank(b.rank);

      // If ranks are different, sort by rank
      if (rankA !== rankB) {
        return rankA - rankB;
      }

      // Handle teams that haven't started (total score of 0)
      const aNotStarted = a.team_score_total === "0" || a.team_score_total === 0;
      const bNotStarted = b.team_score_total === "0" || b.team_score_total === 0;
      
      if (aNotStarted && !bNotStarted) return 1;
      if (!aNotStarted && bNotStarted) return -1;
      if (aNotStarted && bNotStarted) {
        // If both haven't started, prioritize owner's team first
        const isUserTeamA = a.owner.id === currentUserId;
        const isUserTeamB = b.owner.id === currentUserId;
        
        if (isUserTeamA) return -1;
        if (isUserTeamB) return 1;
        
        // If neither is owner's team, sort by world ranking
        return parseFloat(a.team_world_ranking_average) - parseFloat(b.team_world_ranking_average);
      }

      // For tied ranks, sort by world ranking
      return parseFloat(a.team_world_ranking_average) - parseFloat(b.team_world_ranking_average);
    });
  }, [teams]);

  useEffect(() => {
    const newRanks = {};
    sortedTeams.forEach(team => {
      newRanks[team.id] = team.rank;
    });
    setPreviousRanks(newRanks);
  }, [sortedTeams]);

  const toggleRow = (teamId) => {
    setExpandedRows((prev) => ({ ...prev, [teamId]: !prev[teamId] }));
  };

  const getScoreClass = (score) => {
    if (score === "E" || score === "--") return "";
    const numScore = parseInt(score);
    if (isNaN(numScore)) return "";
    return numScore < 0 ? "golf-pools-score-under" : "";
  };

  const getRankDisplay = (rank, index, tournamentStatus) => {
    if (rank === "MC") return <span className="golf-pools-mc-indicator">Cut</span>;
    return rank;
  };

  return (
    <>
      <div className="leaderboard-table-wrapper">
        <table className="leaderboard-table">
          <thead>
            <tr>
              <th className="rank-header">Rank</th>
              <th className="team-header">Team</th>
              <th className="world-rank-header">World Rank (Avg)</th>
              <th className="score-header">Score</th>
              <th className="score-header">To Par</th>
              <th className="details-header">Details</th>
            </tr>
          </thead>
          <tbody>
            {sortedTeams.map((team, index) => {
              const hasPositionChanged = previousRanks[team.id] && previousRanks[team.id] !== team.rank;
              const isTeamViewable = canViewTeam(team);
              return (
                <React.Fragment key={team.id}>
                  <tr className={`${expandedRows[team.id] ? "expanded-row" : ""} ${hasPositionChanged ? "position-changed" : ""} ${isTeamViewable ? "clickable" : "disabled-row"}`}>
                    <td className="rank-cell">
                      {getRankDisplay(team.rank, index, team.pool?.tournament_status)}
                    </td>
                    <td className="team-name-cell">
                      {team.owner.full_name}
                    
                      {team.pool?.tournament_status === "Official" && team.pool?.current_round === "4" && team.rank === "1" && (
                        <FaTrophy 
                          className="team-name-trophy" 
                          style={{ 
                            color: '#FFD700',
                            marginLeft: '8px',
                            fontSize: '1.25rem',
                            filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))',
                            verticalAlign: 'middle'
                          }} 
                        />
                      )}
                    </td>
                    <td className="world-rank-cell">
                      {isTeamViewable ? parseFloat(team.team_world_ranking_average).toFixed(2) : "--"}
                    </td>
                    <td className="score-cell">
                      {isTeamViewable ? team.team_score_total : "--"}
                    </td>
                    <td className={`score-cell ${getScoreClass(team.team_score_to_par)}`}>
                      {isTeamViewable ? team.team_score_to_par : "--"}
                    </td>
                    <td className="action-cell">
                      {isTeamViewable ? (
                        <button 
                          onClick={() => toggleRow(team.id)} 
                          className={`expand-collapse-btn ${expandedRows[team.id] ? 'expanded' : ''}`}
                          aria-label={expandedRows[team.id] ? "Hide team" : "Show team"}
                        >
                          {expandedRows[team.id] ? <IoChevronDownCircle /> : <IoChevronBackCircle />}
                        </button>
                      ) : (
                        <div className="locked-container" title={isTournamentStarted ? "You can only view your own team" : "Team details will be visible when the tournament begins"}>
                          <TbGolfOff className="locked-icon" />
                        </div>
                      )}
                    </td>
                  </tr>
                  {expandedRows[team.id] && isTeamViewable && (
                    <tr className="details-row">
                      <td colSpan="6">
                        <div className="details-container">
                          <TeamLineItemsTable lineItems={teamLineItems} teamId={team.id} />
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Mobile Cards View */}
      <div className="leaderboard-cards-mobile">
        {sortedTeams.map((team, index) => {
          const hasPositionChanged = previousRanks[team.id] && previousRanks[team.id] !== team.rank;
          const isTeamViewable = canViewTeam(team);
          return (
            <div key={team.id} className={`leaderboard-card ${hasPositionChanged ? "position-changed" : ""} ${!isTeamViewable ? "disabled-card" : ""}`}>
              <div className="leaderboard-card-header">
                <div className="leaderboard-card-row">
                  <span className="leaderboard-card-label">Rank:</span>
                  <span className="leaderboard-card-value">
                    {getRankDisplay(team.rank, index, team.pool?.tournament_status)}
                  </span>
                </div>
              </div>
              <div className="leaderboard-card-content">
                <div className="leaderboard-card-row">
                  <span className="leaderboard-card-label">Team:</span>
                  <span className="leaderboard-card-value">
                    {team.owner.full_name}
                 
                    {team.pool?.tournament_status === "Official" && team.pool?.current_round === "4" && team.rank === "1" && (
                      <FaTrophy 
                        className="team-name-trophy" 
                        style={{ 
                          color: '#FFD700',
                          marginLeft: '8px',
                          fontSize: '1.25rem',
                          filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))',
                          verticalAlign: 'middle'
                        }} 
                      />
                    )}
                  </span>
                </div>
                <div className="leaderboard-card-row">
                  <span className="leaderboard-card-label">World Rank (Avg):</span>
                  <span className="leaderboard-card-value">
                    {isTeamViewable ? parseFloat(team.team_world_ranking_average).toFixed(2) : "--"}
                  </span>
                </div>
                <div className="leaderboard-card-row">
                  <span className="leaderboard-card-label">Score:</span>
                  <span className="leaderboard-card-value">{isTeamViewable ? team.team_score_total : "--"}</span>
                </div>
                <div className="leaderboard-card-row">
                  <span className="leaderboard-card-label">To Par:</span>
                  <span className={`leaderboard-card-value ${getScoreClass(team.team_score_to_par)}`}>
                    {isTeamViewable ? team.team_score_to_par : "--"}
                  </span>
                </div>
                {isTeamViewable ? (
                  <div className="leaderboard-card-row" style={{ justifyContent: 'center' }}>
                    <button 
                      onClick={() => toggleRow(team.id)} 
                      className="golf-pools-back-link"
                      style={{ 
                        gap: '8px',
                        color: '#1a472a',
                        transition: 'all 0.2s ease',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '6px 12px',
                        borderRadius: '4px',
                        border: '1px solid transparent'
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.color = expandedRows[team.id] ? '#dc2626' : '#0f2b19';
                        e.currentTarget.style.border = `1px solid ${expandedRows[team.id] ? '#dc2626' : '#0f2b19'}`;
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.color = '#1a472a';
                        e.currentTarget.style.border = '1px solid transparent';
                      }}
                      aria-label={expandedRows[team.id] ? "Hide team" : "Show team"}
                    >
                      {expandedRows[team.id] ? (
                        <>
                          Hide Team <IoEyeOffOutline style={{ marginLeft: '4px' }} />
                        </>
                      ) : (
                        <>
                          Show Team <IoEyeOutline style={{ marginLeft: '4px' }} />
                        </>
                      )}
                    </button>
                  </div>
                ) : (
                  <div className="leaderboard-card-row" style={{ justifyContent: 'center' }}>
                    <div className="locked-message">
                      {isTournamentStarted ? "You can only view your own team" : "Team details will be visible when the tournament begins"}
                    </div>
                  </div>
                )}
                {expandedRows[team.id] && isTeamViewable && (
                  <div className="details-container">
                    <TeamLineItemsTable lineItems={teamLineItems} teamId={team.id} />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default React.memo(TeamTable);
