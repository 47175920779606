import React from "react";
import { FaClock } from "react-icons/fa";
const moment = require("moment-timezone");

function TeamLineItemsTable({ lineItems, teamId }) {
  const filteredLineItems = lineItems.filter((item) => item.team === teamId);
  const currentRound = filteredLineItems[0]?.pool?.current_round;

  console.log("filteredLineItems", filteredLineItems);

  const getScoreClass = (score) => {
    if (!score || score === "--" || score === "-") return "";
    if (score === "E") return "";
    const numScore = parseInt(score);
    if (isNaN(numScore)) return "";
    return numScore < 0 ? "golf-pools-score-under" : "";
  };

  const formatScore = (score) => {
    if (!score || score === "--" || score === "-") return "--";
    if (score === "E") return "E";
    const numScore = parseInt(score);
    if (isNaN(numScore)) return score;
    return numScore > 0 ? `+${numScore}` : numScore;
  };

  const getPlayerStatus = (item, index) => {
    if (item.players_tournament_status === "cut") return "leaderboard-missed-cut";
    if (item.players_tournament_status === "wd") return "leaderboard-withdrawn";
    if (index >= item.pool.num_player_used_for_scoring && item.pool.tournament_status !== "Not Started") {
      return "leaderboard-not-counting";
    }
    return "";
  };

  // Get the current round score based on round number
  const getCurrentRoundScore = (item) => {
    switch (currentRound) {
      case "1": return item.round_1_to_par;
      case "2": return item.round_2_to_par;
      case "3": return item.round_3_to_par;
      case "4": return item.round_4_to_par;
      default: return item.round_1_to_par;
    }
  };


  const sortedLineItems = React.useMemo(() => {
    return [...filteredLineItems].sort((a, b) => {
      // Helper function to get numeric score that handles all cases
      const getNumericScore = (score) => {
        if (!score || score === "--" || score === "-") return 999;
        if (score === "E") return 0;
        const parsed = parseInt(score);
        return isNaN(parsed) ? 999 : parsed;
      };

      // Helper function to check if a player has a special status (cut, dq, wd)
      const hasSpecialStatus = (player) => {
        return ['cut', 'dq', 'wd'].includes(player.players_tournament_status?.toLowerCase());
      };

      // Special status players go to bottom
      const aSpecial = hasSpecialStatus(a);
      const bSpecial = hasSpecialStatus(b);
      if (aSpecial && !bSpecial) return 1;
      if (!aSpecial && bSpecial) return -1;

      // Only apply "has started" logic during Round 1
      if (currentRound === "1") {
        const hasStarted = (player) => {
          return player.total_score && player.total_score !== "--" && 
                 player.holes_played && player.holes_played !== "--";
        };

        const aStarted = hasStarted(a);
        const bStarted = hasStarted(b);

        if (aStarted && !bStarted) return -1;
        if (!aStarted && bStarted) return 1;

        // If neither has started, sort by tee time
        if (!aStarted && !bStarted) {
          if (!a.tee_time && !b.tee_time) return 0;
          if (!a.tee_time) return 1;
          if (!b.tee_time) return -1;
          return moment(a.tee_time).diff(moment(b.tee_time));
        }
      }

      // Sort by score
      const scoreA = getNumericScore(a.total_score_to_par);
      const scoreB = getNumericScore(b.total_score_to_par);
      
      if (scoreA !== scoreB) {
        return scoreA - scoreB; // Lower scores first (-4 before +2)
      }
      
      // If scores are tied, sort by world ranking
      const rankA = parseInt(a.player_world_ranking) || 999;
      const rankB = parseInt(b.player_world_ranking) || 999;
      return rankA - rankB;
    });
  }, [filteredLineItems, currentRound]);

  return (
    <div className="leaderboard-player-details">
      {/* Desktop View */}
      <table className="leaderboard-line-items desktop-table" style={{ width: '100%', tableLayout: 'fixed' }}>
        <colgroup>
          <col style={{ width: '30%' }} /> {/* Player column */}
          <col style={{ width: '10%' }} /> {/* R1 */}
          <col style={{ width: '10%' }} /> {/* R2 */}
          <col style={{ width: '10%' }} /> {/* R3 */}
          <col style={{ width: '10%' }} /> {/* R4 */}
          <col style={{ width: '10%' }} /> {/* Thru */}
          <col style={{ width: '10%' }} /> {/* Score */}
          <col style={{ width: '10%' }} /> {/* To Par */}
        </colgroup>
        <thead>
          <tr>
            <th className="leaderboard-player-column">Player</th>
            <th className="leaderboard-round-column">R1</th>
            <th className="leaderboard-round-column">R2</th>
            <th className="leaderboard-round-column">R3</th>
            <th className="leaderboard-round-column">R4</th>
            <th className="leaderboard-thru-column">Thru</th>
            <th className="leaderboard-score-column">Score</th>
            <th className="leaderboard-to-par-column">To Par</th>
          </tr>
        </thead>
        <tbody>
          {sortedLineItems.map((item, index) => {
            const playerStatus = getPlayerStatus(item, index);
            const holesPlayed = ["--", "-"].includes(item.holes_played) && item.tee_time != null 
              ? <span className="leaderboard-tee-time"><FaClock className="leaderboard-clock-icon" />{moment(item.tee_time).format("h:mm A")}</span>
              : item.holes_played;

            return (
              <tr key={item.id} className={playerStatus}>
                <td className="leaderboard-player-cell">
                  <span className="leaderboard-player-name">{item.player.player_name}</span>
                </td>
                <td className={`leaderboard-round-cell ${getScoreClass(item.round_1_to_par)}`}>
                  {item.round_1_to_par === "MC" ? <span className="leaderboard-mc-indicator">Cut</span> : 
                   item.players_tournament_status === "wd" ? <span className="leaderboard-mc-indicator">WD</span> : 
                   formatScore(item.round_1_to_par)}
                </td>
                <td className={`leaderboard-round-cell ${getScoreClass(item.round_2_to_par)}`}>
                  {item.round_2_to_par === "MC" ? <span className="leaderboard-mc-indicator">Cut</span> : 
                   item.players_tournament_status === "wd" ? <span className="leaderboard-mc-indicator">WD</span> : 
                   formatScore(item.round_2_to_par)}
                </td>
                <td className={`leaderboard-round-cell ${getScoreClass(item.round_3_to_par)}`}>
                  {item.round_3_to_par === "MC" ? <span className="leaderboard-mc-indicator">Cut</span> : 
                   item.players_tournament_status === "wd" ? <span className="leaderboard-mc-indicator">WD</span> : 
                   formatScore(item.round_3_to_par)}
                </td>
                <td className={`leaderboard-round-cell ${getScoreClass(item.round_4_to_par)}`}>
                  {item.round_4_to_par === "MC" ? <span className="leaderboard-mc-indicator">Cut</span> : 
                   item.players_tournament_status === "wd" ? <span className="leaderboard-mc-indicator">WD</span> : 
                   formatScore(item.round_4_to_par)}
                </td>
                <td className="leaderboard-thru-cell">
                  {item.players_tournament_status === "cut" ? <span className="leaderboard-mc-indicator">Cut</span> : 
                   item.players_tournament_status === "wd" ? <span className="leaderboard-mc-indicator">WD</span> : 
                   holesPlayed}
                </td>
                <td className="leaderboard-total-cell">
                  {item.total_score}
                </td>
                <td className={`leaderboard-to-par-cell leaderboard-missed-cut${getScoreClass(item.total_score_to_par)}`}>
                  {formatScore(item.total_score_to_par)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Mobile View */}
      <table className="leaderboard-line-items mobile-table">
        <thead>
          <tr>
            <th className="leaderboard-player-column">Player</th>
            <th className="leaderboard-round-column">R{currentRound || "1"}</th>
            <th className="leaderboard-thru-column">Thru</th>
            <th className="leaderboard-to-par-column">Total</th>
          </tr>
        </thead>
        <tbody>
          {sortedLineItems.map((item, index) => {
            const playerStatus = getPlayerStatus(item, index);
            const holesPlayed = ["--", "-"].includes(item.holes_played) && item.tee_time != null 
              ? <span className="leaderboard-tee-time"><FaClock className="leaderboard-clock-icon" />{moment(item.tee_time).format("h:mm A")}</span>
              : item.holes_played;

            const roundScore = getCurrentRoundScore(item);

            return (
              <tr key={item.id} className={playerStatus}>
                <td className="leaderboard-player-cell">
                  <span className="leaderboard-player-name">{item.player.player_name}</span>
                </td>
                <td className={`leaderboard-round-cell ${getScoreClass(roundScore)}`}>
                  {roundScore === "MC" ? <span className="leaderboard-mc-indicator">Cut</span> : 
                   item.players_tournament_status === "wd" ? <span className="leaderboard-mc-indicator">WD</span> : 
                   formatScore(roundScore)}
                </td>
                <td className="leaderboard-thru-cell">
                  {item.players_tournament_status === "cut" ? <span className="leaderboard-mc-indicator">Cut</span> : 
                   item.players_tournament_status === "wd" ? <span className="leaderboard-mc-indicator">WD</span> : 
                   holesPlayed}
                </td>
                <td className={`leaderboard-to-par-cell ${getScoreClass(item.total_score_to_par)}`}>
                  {formatScore(item.total_score_to_par)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default React.memo(TeamLineItemsTable);
